import { AxiosResponse } from "axios";
import { atom, SetterOrUpdater, useRecoilState } from "recoil";

import { getClient } from "../../utils/api";

interface State {
    loading: boolean;
    error: Error | null;
    url: string | null;
}

interface Hook extends State {
    updateCreditAmount: (amount: number) => void;
    resetError: () => void;
}

interface StripeResponse {
    url: string;
}

const NAMESPACE = "services:stripe";
const INITIAL_STATE = {
    error: null,
    loading: false,
    url: null,
};

const serviceState = atom<State>({
    default: INITIAL_STATE,
    key: NAMESPACE,
});

const updateCreditAmount =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (amount: number): Promise<void> => {
        try {
            setState({
                ...state,
                loading: true,
            });

            const response: AxiosResponse<StripeResponse> =
                await getClient().post("/account/checkout", {
                    quantity: amount,
                });

            setState({
                ...state,
                loading: false,
                url: response.data.url,
            });

            window.location.href = response.data.url;
        } catch (error) {
            setState({
                ...state,
                error,
                loading: false,
            });
        }
    };

const resetError = (state: State, setState: SetterOrUpdater<State>) => () => {
    setState({
        ...state,
        error: null,
    });
};

export const useStripeService = (): Hook => {
    const [state, setState] = useRecoilState(serviceState);

    return {
        ...state,
        resetError: resetError(state, setState),
        updateCreditAmount: updateCreditAmount(state, setState),
    };
};
