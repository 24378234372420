import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/styles/makeStyles";
import { navigate } from "gatsby";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import { useStripeService } from "../../services/stripe";
import { shared } from "../../utils/global/shared";

const useStyles = makeStyles(() => ({
    button: {
        lineHeight: "0",
    },
    centered: {
        margin: "0 auto",
    },
    footer: {
        marginTop: "200px",
    },
    fullWidth: {
        width: "100%",
    },
}));

const StripeSuccess = (): JSX.Element => {
    const { loading } = useStripeService();
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Loader loading={loading}>
            <Container maxWidth="lg" fixed>
                <Grid
                    container
                    spacing={8}
                    direction="column"
                    justifyContent="center"
                >
                    <Grid
                        className={`${classes.centered}  ${classes.fullWidth}`}
                        item
                        xs={8}
                    >
                        <Button
                            className={classes.button}
                            variant="text"
                            color="primary"
                            onClick={() => navigate("/dashboard")}
                            startIcon={<ArrowBackIcon />}
                        >
                            {t("Back to dashboard")}
                        </Button>
                    </Grid>
                    <Grid
                        className={`${classes.centered}  ${classes.fullWidth}`}
                        item
                        xs={6}
                    >
                        <Typography variant="h1">
                            {t("Stripe success")}
                        </Typography>
                    </Grid>
                    <Grid
                        className={`${classes.footer} ${classes.centered}`}
                        item
                    >
                        <Typography variant="body2">
                            <Trans i18nKey="Footer">
                                Contact us at
                                <Link
                                    href={`mailto:${shared.email}`}
                                    underline="hover"
                                >
                                    {shared.email}
                                </Link>
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Loader>
    );
};

export default StripeSuccess;
